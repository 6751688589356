import Api from '@/request/request.js';

/* 一级菜单 */
export async function getFirstList() {
    const resp = await Api.post('/api/type/findFirstList');
    return resp.data;
}
/* 菜单列表 */
export async function getMenuList() {
    const resp = await Api.post('/api/type/menuList')
    return resp.data
}

/* 基础数据 */
export async function getTypeInfo(data) {
    const resp = await Api.post('/api/type/info', data);
    return resp.data;
}
/* 子类型列表 */
export async function getTypeChild(data) {
    const resp = await Api.post('/api/type/child', data);
    return resp.data;
}

/* 基础信息*/
export async function getInfoList() {
    const resp = await Api.post('/api/info/list');
    return resp.data;
} 