export async function downloadFile(fileId) {
    try {
        const resp = await fetch('https://cqcfctech.com/cfc/file/download?fileUUID=' + fileId);
        return resp.blob();
    } catch (error) {
        // 文件下载失败
    }
}

function blobToDataURI(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

export async function downloadImage(fileId) {
    if (!fileId) {
        return 'not find fileId'
    }

    try {
        const resp = await fetch('https://cqcfctech.com/cfc/file/download?fileUUID=' + fileId)
        const blob = await resp.blob()
        return blobToDataURI(blob)
    } catch (error) {
        // 文件下载失败
        return 'file download error'
    }
}
