import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import BaiduMap from 'vue-baidu-map'
import 'animate.css';

Vue.config.productionTip = false
Vue.config.silent = true;//去除控制台中Vue warn
Vue.use(ElementUI)
Vue.use(BaiduMap, {
  ak: 'Vyf2wYVwGqIkFLblloexF9F1lUzEUh2B'
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
