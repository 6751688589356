<template>
  <div
    :style="{
      height: [$route.path == '/about'] ? '' : '100%'
    }"
    style="width:100%"
  >
    <template v-if="!base64">
      <div class="loading-box">Loading...</div>
    </template>
    <!-- src="@/assets/img/train/2.png" -->
    <img
      v-else-if="this.fileId == null || this.fileId == ''"
      :class="imgClass"
      :style="[defaultStyle, imgStyle]"
    />
    <img
      v-else
      :src="base64"
      :class="imgClass"
      :style="[defaultStyle, imgStyle]"
    />
  </div>
</template>

<script>
import { downloadImage } from "@/api/file/file.js";

export default {
  props: ["fileId", "imgClass", "imgStyle"],
  data() {
    return {
      base64: "",
      defaultStyle: {
        width: "100%"
      }
    };
  },
  mounted() {
    this.handleImgBase64(this.fileId);
  },
  methods: {
    async handleImgBase64(id) {
      const base64 = await downloadImage(id);
      this.base64 = base64;
    }
  },
  computed: {},
  watch: {
    fileId() {
      this.handleImgBase64(this.fileId);
    }
  }
};
</script>

<style scoped>
.loading-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
