import axios, { AxiosError } from 'axios';

const Api = axios.create({
    baseURL: '/cfc',
    withCredentials: true,
    timeout: 15000,
});

Api.interceptors.request.use((config) => {
    config.headers = {
        'Content-Type': 'application/json;charset=utf-8',
    };
    return config;
});

Api.interceptors.response.use(
    (resp) => {
        const { code, message } = resp.data;
        // console.log(resp.data)
        if (code == 1) {
            return resp.data;
        } else if (code !== '0') {
            AxiosError.error({ message: `${message && message.length > 100 ? `${message.slice(0, 100)}...` : message}` });

            return Promise.reject(new Error(message));
        }
        return resp.data;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default Api;
