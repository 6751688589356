<template>
  <div>
    <topMenu />
    <el-main>
      <router-view class="main container" />
    </el-main>
    <index />
  </div>
</template>

<script>
import topMenu from "@/components/TopMenu/topMenu.vue";
import index from "./components/Footer";
export default {
  name: "App",
  components: {
    topMenu,
    index,
  },
};
</script>

<style lang="less">
body {
  min-width: 1200px;
  max-width: 1920px;
  overflow: auto;
}
.container {
  overflow: hidden;
  min-width: 1200px;
  max-width: 1920px;
}
.el-main {
  overflow: hidden;
  padding: 0px !important;
  .main {
    margin-top: 60px;
  }
}
.el-carousel__item.is-animating {
  transition: transform 1.3s ease-in-out !important;
}
.el-menu.el-menu--horizontal {
  min-width: 836px !important;
  // height: 80px !important;
  // width: 80px !important;
}
.el-menu--horizontal > .el-menu-item {
  height: 80px !important;
  line-height: 80px !important;
}
body {
  min-width: 1200px;
  max-width: 1920px;

}
.el-menu--horizontal > .el-submenu .el-submenu__title {
  line-height: 80px !important;
  height: 80px !important;
}
/deep/ .el-cascader-node__label :hover {
  color: #409eff !important;
  font-weight: 700 !important;
}
// .el-tabs .el-tabs--top {
//   width: 1200px;
// }
// .el-tabs__nav {
//   width: 100%;
// overflow: auto;
// }
// .el-tabs__nav .is-top{}
// .el-tabs__nav {
//   width: 1350px;
// }
.BMap_bubble_title{
  line-height: 55px !important;
  font-size: 18px !important;
}
</style>
