const routes = [
  {
    // 默认路由
    path: '/',
    redirect: '/homepage',
    component: () => import('@/views/Home/index.vue'),
  },
  {
    path: '/homepage',
    name: '首页',
    component: () => import('@/views/Home/index.vue')
  },
  {
    path: '/about',
    name: '关于公司',
    component: () => import('@/views/Company/index.vue'),
  },
  {
    path: '/product',
    name: "软件产品",
    component: () => import('@/components/commonPages/Products')
  },
  {
    path: '/train',
    name: "实训板块",
    component: () => import('@/components/commonPages/Train')
  },
  {
    path: '/information',
    name: "企业信息化",
    component: () => import('@/components/commonPages/Information')
  },
  {
    path: '/partners',
    name: "合作伙伴",
    component: () => import('@/components/commonPages/Partners')
  },
  {
    path: '/news',
    name: "新闻动态",
    component: () => import('@/components/commonPages/News')
  },
  {
    name: '联系我们',
    path: '/contact',
    component: () => import('@/components/commonPages/Contact/index.vue'),
    children: [
      {
        path: '/contact-us',
        name: "联系我们",
        component: () => import('@/components/commonPages/Contact/contact.vue')
      },
      {
        path: '/messages',
        name: "在线留言",
        component: () => import('@/components/commonPages/Contact/message.vue')
      },

    ]
  },
  {/* 新闻详情页 */
    path: '/news-detail',
    component: () => import('@/components/commonPages/News/detail.vue')
  },
]

export default routes
