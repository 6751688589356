<template>
  <div class="menu">
    <div class="logo">
      <img src="@/assets/toplogo.png" class="image" />
    </div>
    <el-menu
      mode="horizontal"
      background-color="#F5F8FC"
      text-color="#333333"
      active-text-color="#2A95FF"
      :default-active="$route.path"
      router="true"
    >
      <template v-for="list in menuList">
        <el-submenu
          v-if="list.children && list.children.lenght !== 0"
          :key="list.name"
        >
          <template slot="title">
            <span>{{ list.name }}</span>
          </template>
          <template v-for="item in list.children">
            <el-menu-item :index="item.path" :key="item.path">
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </template>
        </el-submenu>
        <el-menu-item v-else :index="list.path" :key="list.path">{{
          list.name
        }}</el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import routes from "@/router/routes";
export default {
  data() {
    return {
      menuList: [],
    };
  },
  created() {
    const menuList = routes.filter((item) => {
      if (item.name) {
        return item;
      }
    });
    this.menuList = menuList;
  },
};
</script>

<style lang="less" scoped>
.menu {
  display: flex;
  position: fixed;
  justify-content: space-around;
  height: 80px;
  width: 100%;
  z-index: 9999;
  background-color: #f5f8fc;
  .logo {
    height: 80px;
    overflow: hidden;
    .image {
      margin-top: 18px;
    }
  }
}
.el-menu-item {
  font-size: 16px;
}
.el-menu--horizontal > .el-submenu .el-submenu__title {
  // height: 80px !important;
  line-height: 80px;
}
.el-submenu__title span {
  font-size: 16px !important;
}
</style>