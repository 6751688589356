<template>
  <div class="footer-box">
    <div class="footer">
      <div class="footer-left">
        <div class="name">{{ leftInfo.name }}</div>
        <el-row justify="center" align="middle" type="flex">
          <BusinessImage
            v-if="fileId"
            :file-id="fileId"
            style="width: 120px"
          ></BusinessImage>
        </el-row>
        <div class="wx-text">{{ leftInfo.wxtext }}</div>
      </div>
      <div class="footer-right">
        <el-row style="margin-bottom: 80px">
          <el-col :span="5" offset="1" center>
            <div class="item-box">
              <div class="icon-text">
                <img src="@/assets/icons/phone.png" style="margin-right: 5px" />
                <div class="text">{{ rightInfo.textPhone }}</div>
              </div>
              <div class="text-value">{{ rightInfo.phone }}</div>
            </div>
          </el-col>
          <el-col :span="8" style="padding-right: 30px">
            <div class="item-box">
              <div class="icon-text">
                <img
                  src="@/assets/icons/address.png"
                  style="margin-right: 5px"
                />
                <div class="text">{{ rightInfo.textAddress }}</div>
              </div>
              <div class="text-value">{{ rightInfo.address }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item-box">
              <div class="icon-text">
                <img src="@/assets/icons/QQ.png" style="margin-right: 5px" />
                <!-- <div class="text">{{ rightInfo.textEmail }}</div> -->
                <div class="text">备案号</div>
              </div>
              <div class="text-value">
                <a href="https://beian.miit.gov.cn/">渝ICP备2022013775号-1</a>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5" offset="1">
            <div class="item-box">
              <div class="icon-text">
                <img src="@/assets/icons/QQ.png" style="margin-right: 5px" />
                <div class="text">{{ rightInfo.textLine }}</div>
              </div>
              <div class="text-value">{{ rightInfo.Line }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="item-box">
              <div class="icon-text">
                <img src="@/assets/icons/phone.png" style="margin-right: 5px" />
                <div class="text">{{ rightInfo.textEmail }}</div>
              </div>
              <div class="text-value">{{ rightInfo.email }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfoList } from "@/api/home/home";
import BusinessImage from "@/components/BusinessImage/BusinessImage.vue";
export default {
  components: { BusinessImage },
  data() {
    return {
      leftInfo: {},
      rightInfo: {},
    };
  },
  computed: {
    fileId() {
      return this.leftInfo.fileId;
    },
  },
  methods: {
    async getList() {
      const res = await getInfoList();
      this.leftInfo = {
        name: res.CORPORATE.value,
        wxtext: res.WECHAT.name,
        fileId: res.WECHAT.attachment[0].id,
      };
      this.rightInfo = {
        textPhone: res.TEL.name,
        phone: res.TEL.value,
        textAddress: res.ADDRESS.name,
        address: res.ADDRESS.value,
        textLine: res.HOT.name,
        Line: res.HOT.value,
        textEmail: res.MAIL.name,
        email: res.MAIL.value,
      };
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.footer-box {
  background-color: #292d38;
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 60px 50px;
    .footer-left {
      width: 40%;
      .name {
        font-size: 36px;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 30px;
        text-align: center;
      }
      .wxImg {
        text-align: center;
        .img {
          height: 120px;
          width: 120px;
        }
      }
      .wx-text {
        font-size: 18px;
        color: #ffffff;
        text-align: center;
      }
    }
    .footer-right {
      width: 50%;
      .item-box {
        .icon-text {
          display: flex;
          .text {
            font-size: 18px;
            color: #666666;
          }
        }
        .text-value {
          font-size: 18px;
          color: #666666;
          margin-top: 10px;
          a {
            text-decoration: none;
            font-size: 18px;
            color: #666666;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
